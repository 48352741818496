<div class="">
    <h4>{{ 'order.analysesForSample' | translate }}</h4>
    <!-- Customer mode-->
    <ng-container>
        <div class="list-group">
            <div
                class="list-group-item flex-column align-items-start"
                *ngFor="let analysis of selectedAnalyses; let i = index"
            >
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                        <span class="badge badge-info mr-2" *ngIf="analysis.groupAnalysis">{{
                            'order.groupBadge' | translate
                        }}</span
                        >{{ analysis.name }}
                    </h5>
                    <button class="btn btn-small btn-outline-danger align-self-baseline" (click)="removeAnalysis(i)">
                        {{ 'order.removeAnalysis' | translate }}
                    </button>
                </div>
                <p class="mb-1">
                    {{ analysis.description || '' }}
                </p>

                <div *ngIf="analysis.groupAnalysis">
                    <span class="my-2">{{ 'order.groupAnalysisInfo' | translate }}</span>
                    <ul>
                        <li *ngFor="let child of analysis.groupItems">{{ cache.analysisCache.get(child)!.name }}</li>
                    </ul>
                </div>

                <div
                    *ngIf="isBird && (analysis.certificateAvailable || showCertInfo[analysis.id])"
                    class="row"
                    [formGroup]="$any(orderItemsForm.controls[i])"
                >
                    <label
                        [pTooltip]="tooltipContent"
                        [tooltipPosition]="'top'"
                        class="col-sm-8 col-md-5 col-form-label"
                        >{{ 'order.includingDecorativeCert' | translate }}
                        <span><i class="fas fa-question-circle"></i></span>
                    </label>
                    <ng-template #tooltipContent>
                        <div class="flex flex-column align-items-center">
                            <div><img src="/assets/CERT.SK.jpg" height="120" class="mr-2" /></div>
                            <div>{{ 'order.decorativeCertExplained' | translate }}</div>
                        </div>
                    </ng-template>
                    <div class="col-sm-4 col-md-2 pt-2" title="{{ 'order.includingDecorativeCert' | translate }}">
                        <span class="toggle-wrap pt-1">
                            <label class="c-switch c-switch-label c-switch-pill c-switch-primary">
                                <input
                                    class="c-switch-input"
                                    type="checkbox"
                                    formControlName="includingDecorativeCert"
                                />
                                <span class="c-switch-slider" data-checked="✓" data-unchecked="✕"></span>
                            </label>
                        </span>
                    </div>

                    <slovgen-ui-note
                        class="col-12"
                        *ngIf="analysis.groupAnalysis && showCertInfo[analysis.id]"
                        note="{{ 'analysis.certificateForBirdSexingOnly' | translate }}"
                    ></slovgen-ui-note>
                </div>
                <div
                    *ngIf="analysis.showPriorityDeliveryInfo"
                    class="row"
                    [formGroup]="$any(orderItemsForm.controls[i])"
                >
                    <label
                        class="col-6 col-sm-8 col-md-5 col-form-label"
                        title="{{ 'analysis.priorityDeliveryUpSell' | translate }}"
                        >{{ 'analysis.priorityDeliveryUpSell' | translate }}</label
                    >
                    <div class="col-6 col-sm-4 col-md-7 pt-2">
                        <div class="field-checkbox">
                            <p-radioButton
                                [inputId]="i + 'priority' + analysis.id"
                                [value]="true"
                                [formControlName]="'priorityDelivery'"
                            ></p-radioButton>
                            <label
                                [for]="i + 'priority' + analysis.id"
                                class="ml-2"
                                [innerHTML]="
                                    'analysis.priorityDeliveryYes' | translate : { days: analysis.priorityDuration }
                                "
                            ></label>
                        </div>
                        <div class="field-checkbox">
                            <p-radioButton
                                [inputId]="i + 'standard' + analysis.id"
                                [value]="false"
                                [formControlName]="'priorityDelivery'"
                            ></p-radioButton>
                            <label
                                [for]="i + 'standard' + analysis.id"
                                class="ml-2"
                                [innerHTML]="
                                    'analysis.priorityDeliveryNo' | translate : { days: analysis.standardDuration }
                                "
                            ></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <form novalidate>
        <div class="form-row">
            <div class="prompt col-12 py-3">{{ 'order.analysis.prompt' | translate }}</div>
            <div class="col-md-8 pl-0">
                <ng-select
                    *ngIf="analysisSelectItems"
                    [items]="analysisSelectItems"
                    appearance="outline"
                    bindValue="id"
                    bindLabel="label"
                    [formControl]="selectedAnalysisId"
                    [virtualScroll]="true"
                ></ng-select>
            </div>
            <div class="col-md-4 col-xl-2 pl-0 md:pl-2">
                <button
                    class="btn btn-small btn-block btn-outline-primary my-1 md:my-0"
                    [disabled]="selectedAnalysisId.value ? null : 'disabled'"
                    (click)="addAnalysis(selectedAnalysisId.value)"
                >
                    {{ 'order.addToBasket' | translate }}
                </button>
            </div>
            <ui-show-errors [control]="orderItemsForm" textClass="font-sm"></ui-show-errors>
            <span
                *ngIf="!adminMode"
                id="emailHelp"
                class="bg-orange-50 border-round border-1 border-orange-500 p-2 form-text text-orange-600 px-1 md:mt-4"
                ><i class="fas fa-exclamation-triangle"></i> {{ 'order.warningSuitableAnalysis' | translate }}</span
            >
        </div>
    </form>
</div>
